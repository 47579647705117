<template>
	<div class="full_layer_wrap">
		<div class="full_layer_container">
			<div class="full_layer_wrap_inner">
				<article class="full_view">
					<div class="view_cont_wrap">
						<h3 class="pop_tit">
							{{  $language.nft.mafia_nft_future }}
							<span class="target_card">{{  $language.nft.card_name }} <em>[{{ nft_card.cartl_nft_kind_name ? nft_card.cartl_nft_kind_name : nft_card.nft_card_name }}]</em></span>
						</h3>
						<template
							v-if="items.length > 0"
						>
						<div class="bright_input">
							<input
								v-model="search.nick"
								type="search" :placeholder="$language.common.search"
							>
						</div>
						<div class="follow_list_wrap">
							<ul
							>
								<template
									v-if="search_list.length > 0"
								>
								<li
									v-for="(account, index) in search_list"
									:key="'item_' + index"
								>
									<div class="bright_radio">

										<input
											v-model="item.friend_member_number"
											type="radio" name="follower"
											:value="account.friend_member_number"

											:id="'follower-' + account.friend_member_number"
										>
										<label
											:for="'follower-' + account.friend_member_number">{{  account.nickname  }}</label>
									</div>
								</li>
								</template>
								<li
									v-else
									style="font-size: 14px; text-align: center;"
								>{{  $language.common.no_searched_friend }}</li>
							</ul>
						</div>
						</template>
						<div
							v-else
						>
							<div class="list_none" style="overflow: hidden; margin-top: 0">
								<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
								<span>{{  $language.common.cannotFutureMember }}</span>
							</div>
						</div>

					</div>
					<div class="btn_wrap">
						<button class="pop_btn btn_fill_gray" @click="$emit('cancel')">{{  $language.common.cancel }}</button>
						<button
							v-if="items.length > 0"
							class="pop_btn btn_fill_blue"
							@click="click"
						>{{  $language.common.futures }}</button>
					</div>
				</article>
			</div>
		</div>
		<div class="bg_overlay"></div>
	</div>
</template>

<script>
export default {
	name: 'mafia025'
	, props: ['nft_card', 'user']
	, data: function(){
		return {
			program: {
				name: 'NFT' + this.$language.common.futures
			}
			, type: this.$route.params.type ? this.$route.params.type: 'follower'
			, items: []
			, search_list: []
			, search: {
				nick: ''
			}
			, item: {
				friend_member_number: ''
			}
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_friends_list
					, data: {
						member_number: this.user.member_number
						, page_number: this.$language.base.page_number
						, pagerecnum: this.$language.base.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items = result.data.friend_list
					this.search_list = result.data.friend_list
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,doSearch: function(call){
			// let call = this.search.nick
			let self = this
			this.search_list = []

			this.items.forEach(function(item){
console.log(call + ' : ' + item.nickname)
				if(call == '' || item.nickname.indexOf(call) > -1){
					self.search_list.push(item)
				}
			})

			console.log(this.search_list)
		}
		,click: function(){
			console.log('this.item', this.item)
			if(!this.item.friend_member_number){
				this.$bus.$emit('notify', { type: 'error', message: this.$language.common.no_selected_friend})
				return false
			}else{
				this.$emit('click', this.item)
			}
		}
	}
	,created() {
		this.getData()
	}
	, watch: {
		'search.nick': {
			handler: function(call){
				this.doSearch(call)
			}
		}
	}
}
</script>
