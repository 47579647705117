<template>
	<div class="pupup_wrap">
		<div class="container">
			<div class="row">
				<div class="main_card_wrap mt-20">

					<a
						class="card_info mt-0 text-right"
						@click="$emit('to' , { name: 'mafia020', params: { card_type: card_type, id: $route.params.id }} )"
					>NFT 상세정보 <i class="icon icon-arrow-right"
					></i></a>

					<div class="main_card main_card_type4">
						<div
							class="cover atvImg"
							style="height: 100%"
						>
							<div
								v-if="false"
								class="atvImg-layer"
								:data-img="item.nft_card_img_url"
							></div>
							<img :src="item.nft_card_img_url" style="width: 100%"/>
							<!-- <div class="atvImg-layer" data-img="/assets/image/union_madam.png"></div> -->
							<!-- <div class="atvImg-layer" data-img="/assets/image/union_accountant.png"></div> -->
							<!-- <div class="atvImg-layer" data-img="/assets/image/union_consigliere.png"></div> -->
						</div>
					</div>
				</div>
				<div class="card_union mt-20">
					<strong class="name">{{  card_type_code[item.nft_card_grade_code] }}</strong>
					<span class="type mt-10">UNION CARD</span>
				</div>
				<div
					v-if="item.stakg_fg == 'Y'"
					class="pt-30"
				>
					<button
						v-if="card_type == 'union'"
						class="btn_center btn_l btn_fill_blue"
						@click="onStaking(false)"
					>{{  $language.nft.clear_staking }}</button>
				</div>
				<div
					v-else
					class="btn_area"
				>
					<button
						:disabled="is_present"
						class="btn_l btn_fill_blue"
						:class="{ disable: is_present}"
						@click="onPresent"
					>{{  $language.common.futures }}</button>
					<button
						:disabled="is_widthdrawal"
						class="btn_l btn_fill_blue"
						:class="{ disable: is_widthdrawal}"
						@click="onWithdrawal"
					>{{  $language.common.withdraw }}</button>
					<template
					>
					<button
						v-if="card_type == 'union'"
						class="btn_l btn_fill_blue"
						@click="onStaking(true)"
					>{{  $language.common.staking }}</button>
					</template>
				</div>
			</div>
		</div>


		<mafia024
			v-if="is_024"
			:nft_card="item"
			:user="user"

			@cancel="is_024 = !is_024"
			@click="onPin"
		></mafia024>

		<mafia025
			v-if="is_025"
			:nft_card="item"
			:user="user"

			@cancel="is_025 = !is_025"
			@click="onPin"
		></mafia025>

		<mafia03347
			v-if="is_03347"
			:item="item"

			@cancel="is_03347 = !is_03347"
			@click="toStaking"
		></mafia03347>

		<PIN
			v-if="is_pin"
			:options="pin_option"
			:user="user"
			style="position: fixed; left: 0; top: 0; width: 100%; z-index: 1002"

			@click="pinClick"
			@cancel="pinCancel"
		></PIN>

		<wallet_otp
			v-if="is_otp"
			style="position: fixed; left: 0; top: 0; width: 100%; z-index: 101; background-color: white"

			@click="otpClick"
			@cancel="is_otp = false"
		></wallet_otp>


		<Popup_confirm
			v-if="is_confirm"
			@click="toOtp"
			@cancel="is_confirm = false"
		>
			<template v-slot:title>{{  $language.auth.two_factor_info }}</template>
			<template
				v-slot:main-txt
			>{{  $language.auth.two_factor_status_non_register }}</template>
			<template
				v-slot:sub-txt
			>{{  $language.auth.two_factor_require_google }}</template>
			<template
				v-slot:name-cancel
			>{{  $language.common.cancel }}</template>
			<template
				v-slot:name-confirm
			>{{  $language.common.setting }}</template>
		</Popup_confirm>
	</div>
</template>

<script>
import PIN from "@/view/Auth/mafia007";

import mafia024 from "@/view/Nft/mafia024";
import mafia025 from "@/view/Nft/mafia025";
import mafia03347 from "@/view/Nft/mafia033-47";
import wallet_otp from "@/view/Wallet/wallet_otp";
import Popup_confirm from "@/view/Layout/PopupConfirm";

	export default {
		name: 'mafia0191'
		, props: ['user']
		, components: {wallet_otp, PIN, mafia03347, mafia024, mafia025, Popup_confirm}
		, data: function(){

			return {
				program: {
					name: this.$language.common.union_card
					, type: 'popup'
					, title: 'UNION CARD'
					, from: 'mafia018'
				}
				, is_024: false
				, is_025: false
				, is_03347: false
				, is_pin: false
				, pin_option: {
					pin_type: 'check'
				}
				, is_otp: false
				, item: {

				}
				, type: ''
				, item_on: {

				}
				, card_type: this.$route.params.card_type
				, pin: ''
				, receive_wallet_address: ''
				, is_confirm: false
				, card_type_code: {
					'NF00200041': 'CONSIGLIERE'
					, 'NF00200042': 'ACCOUNTANT'
					, 'NF00200043': 'MADAM'
					, 'NF00200044': 'SPY'
				}
			}
		}
		,computed: {

			is_present: function(){
				let t = false

				if(this.item.main_card_fg || this.item.utilty_card_mount_fg == 'Y' || this.item.stakg_fg == 'Y'){
					t = true
				}
				return t
			}
			,is_widthdrawal: function(){
				let t = false
				if(this.item.main_card_fg || this.item.utilty_card_mount_fg == 'Y' || this.item.stakg_fg == 'Y'){
					t = true
				}
				return t
			}
		}
		, methods: {
			postWithdrawal: async function(otp){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_nft_withdrawal
						, data: {
							member_number: this.user.member_number
							, pinnumber: this.pin
							, gogle_otp_certfc_number: otp
							, wallet_address: this.item_on
							, nft_card_number: this.$route.params.id
							, nft_card_div_code: this.item.nft_card_div_code
						}
						, type: true
					})

					if(!result.success){
						throw result.message
					}

					this.$bus.$emit('notify',  { type: 'success', message: this.$language.nft.complete_withdraw_nftcard, config: { bottom: 0}})
					this.$emit('goBack')

				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify',  { type: 'success', message: e, config: { bottom: 0}})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postPresent: async function(otp){

				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_present
						, data: {
							member_number: this.user.member_number
							, gift_member_number: this.item_on.friend_member_number
							, nft_card_number: this.$route.params.id
							, pinnumber: this.pin
							, gogle_otp_certfc_number: otp
						}
						, type: true
					})

					if(!result.success){
						throw result.message
					}

					this.$bus.$emit('notify',  { type: 'success', message: this.$language.common.complete_futures, config: { bottom: 0}})
					this.$emit('goBack')

				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify',  { type: 'success', message: e, config: { bottom: 0}})
				}finally {
					this.$bus.$emit('on', false)
				}

			}
			, postStaking: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_staking
						, data: {
							member_number: this.user.member_number
							, nft_card_number: this.$route.params.id
						}
						, type: true
					})

					if(result.success){
						this.$bus.$emit('notify',  { type: 'success', message: this.$language.nft.complete_staking, config: { bottom: 0}})
						await this.getMyCard()
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify',  { type: 'error', message: e, config: { bottom: 0}})
				}finally {
					this.is_03347 = false
					this.$bus.$emit('on', false)
				}
			}

			, postUnStaking: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_unstaking
						, data: {
							member_number: this.user.member_number
							, nft_card_number: this.$route.params.id
							, pinnumber: this.pin
						}
						, type: true
					})

					if(result.success){
						this.$bus.$emit('notify',  { type: 'success', message: this.$language.nft.complete_clear_staking, config: { bottom: 0}})
						await this.getMyCard()
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify',  { type: 'error', message: e, config: { bottom: 0}})
				}finally {
					this.is_03347 = false
					this.$bus.$emit('on', false)
				}
			}
			,onPin: function(item){
				this.item_on = item
				this.is_pin = true
				this.pin_option = {
					pin_type: 'check'
					, is_can_cancel: true
					, div: this.type
				}
				this.is_024 = false
				this.is_025 = false
				this.is_03347 = false
			}
			,pinClick: function(pin){
				this.is_pin = false
				this.pin = pin

				if (this.pin_option.div == 'staking') {
					if(this.item.stakg_fg == 'Y'){
						this.postUnStaking()
					}else{
						this.postStaking()
					}
				} else if(this.pin_option.div == 'withdrawal'){
					this.onOtp()
				} else if(this.pin_option.div == 'present'){
					this.postPresent('')
				}
			}
			,pinCancel: function(){
				this.is_pin = false
				this.pin_option= {}
				this.item_on = {}
			}
			, getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						,url: this.$api_url.api_path.get_nft_detail
						,data: {
							member_number: this.user.member_number
							, nft_card_number: this.$route.params.id
						}
						, type: true
					})

					if(result.success){
						this.item = result.data
						if(this.item.main_card_fg == 'Y'){
							this.$set(this.item, 'main_card_fg', true)
							this.item.main_card_fg = true
						}else{
							this.item.main_card_fg = false
							this.$set(this.item, 'main_card_fg', false)
						}

						if(this.item.nft_card_img_url){
							setTimeout(() => {
								window.atvImg()
							}, 100)
						}
					}else{
						this.$bus.$emit('notify',  { type: 'error', message: result.message, config: { bottom: 0}})
					}
				}catch(e){
					this.$bus.$emit('notify',  { type: 'error', message: this.$language.common.error, config: { bottom: 0}})
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, onPresent: function(){
				if(this.user.gogle_otp_reg_fg == 'Y'){
					this.type = 'present'
					this.is_025 = true
				}else{
					this.is_confirm = true
				}
			}
			, onWithdrawal: function(){
				if(this.user.gogle_otp_reg_fg == 'Y'){
					this.type = 'withdrawal'
					this.is_024 = true
				}else{
					this.is_confirm = true
				}
			}
			, toStaking: function(){
				if(this.pin_option.pin_type == 'check'){
					this.onPin()
				}else{
					this.postStaking()
				}
			}
			, onStaking: function(type){
				if(type){
					this.pin_option = {
						pin_type: 'not'
					}
					this.is_03347 = true
				}else{
					this.type = 'staking'
					this.pin_option = {
						pin_type: 'check'
						, div: this.type
					}
					this.is_03347 = true
				}
			}
			, onOtp: function(){
				this.$bus.$emit('onOtp', 'check')
				// this.is_otp = true
			}
			,getMyCard: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						,url: this.$api_url.api_path.get_nft_detail
						,data: {
							member_number: this.user.member_number
							, nft_card_number: this.$route.params.id
						}
						, type: true
					})

					if(result.success){
						this.item = result.data
						if(this.item.main_card_fg == 'Y'){
							this.$set(this.item, 'main_card_fg', true)
							this.item.main_card_fg = true
						}else{
							this.item.main_card_fg = false
							this.$set(this.item, 'main_card_fg', false)
						}
					}else{
						this.$bus.$emit('notify',  { type: 'error', message: result.message, config: { bottom: 0}})
					}
				}catch(e){
					this.$bus.$emit('notify',  { type: 'error', message: this.$language.common.error, config: { bottom: 0}})
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, otpClick: function(otp){

				this.is_otp = false

				if(this.pin_option.div == 'withdrawal'){
					this.postWithdrawal(otp)
				}else if(this.pin_option.div == 'present'){
					this.postPresent(otp)
				}else if(this.pin_option.div == 'staking'){

					if(this.item.stakg_fg == 'Y'){
						this.postUnStaking(otp)
					}else{
						this.postStaking(otp)
					}
				}

				this.pin_option= {}
			}
			, toOtp: function(){
				this.$bus.$emit('to', { name: 'mafia1113'})
			}
			, onOtpCallback: function(){
				this.$bus.$off('otpCallBack')
				this.$bus.$on('otpCallBack', (type, otp) => {
					if(type == 'check'){
						this.otpClick(otp)
					}
				})
			}
		}
		, created() {
			this.$emit('onLoad', this.program)
			this.getData()
			this.onOtpCallback()
		}
	}
</script>

<style>
	.card_info { float: none; text-align: right;}
</style>