<template>
	<div class="full_layer_wrap">
		<div class="full_layer_container">
			<div class="full_layer_wrap_inner">
				<article class="full_view">

					<div class="view_cont_wrap">
						<h3 class="pop_tit pop_tit_logo">MAFIA NFT STAKING</h3>
						<div class="intro_line_lg"><span class="hide">{{ $language.agreement.dividingLine }}</span></div>
						<p
							v-if="item.stakg_fg != 'Y'"
							class="pop_desc"
						>
              {{  $language.nft.staking_question }}
							<span>{{  $language.nft.mining_level }}</span>
						</p>
						<p
							v-else
							class="pop_desc"
						>
              {{  $language.nft.staking_clear_question }}
						</p>
					</div>

					<div class="btn_wrap">
						<button class="pop_btn btn_fill_gray" @click="$emit('cancel')">{{  $language.common.cancel }}</button>
						<button class="pop_btn btn_fill_blue" @click="$emit('click')">{{  $language.common.ok }}</button>
					</div>
				</article>
			</div>
		</div>
		<div class="bg_overlay"></div>
	</div>
</template>

<script>
	export default {
		name: 'mafia033-47'
		, props: ['item']
		, data: function(){
			return {

			}
		}
	}
</script>