
<template>
	<div class="full_layer_wrap">
		<div class="full_layer_container">
			<div class="full_layer_wrap_inner"  style="max-height: 480px; overflow: auto">
				<article class="full_view">
					<div class="nft_card">
						<h3 class="pop_tit">MAFIA {{  $language.nft.nft_withdraw }}</h3>
						<div class="main_card">
							<figure class="nft-card-info">
								<div class="o_square">
									<div class="inner">
										<div class="frame">
											<img
												v-if="nft_card.nft_card_img_url"
												:src="$request.upload_url(nft_card.nft_card_img_url)"
												:alt="nft_card.name"
											/>
											<img
												v-else-if="nft_card.cartl_nft_img_url"
												:src="$request.upload_url(nft_card.cartl_nft_img_url)"
												:alt="nft_card.name"
											/>
										</div>
									</div>
								</div>
							</figure>
							<div class="card_badge">
								<span class="badge_40 " :class="'badge_40_' + nft_card.type "><em class="hide">music</em></span>
								<span class="badge_40 badge_40_capo hide"><em class="hide">capo</em></span>
							</div>
						</div>
						<div class="input_area pt-20">
							<div class="bright_input">
								<input
									v-model="receive_wallet_address"
									type="text" :placeholder="$language.nft.address_deposit_require "
									@change="getTransCommis"
								>
							</div>
							<div class="input_data_result pt-20">
								<span class="result_name">{{  $language.nft.withdraw_gab_fee }}</span>
								<strong class="data">{{  tranmsn_commis }}<em class="pl-5 unit">klay</em></strong>
							</div>
						</div>
					</div>
					<div class="btn_wrap">
						<button class="pop_btn btn_fill_gray" @click="$emit('cancel')">{{  $language.common.cancel }}</button>
						<button class="pop_btn btn_fill_blue" @click="toPin">{{  $language.common.withdraw_apply_on }}</button>
					</div>
				</article>
			</div>
		</div>
		<div class="bg_overlay"></div>
	</div>
</template>

<script>
export default {
	name: 'mafia024'
	, props: ['nft_card', 'user']
	, comments: {}
	, data: function(){
		return {
			tranmsn_commis: 0
			,receive_wallet_address: ''
		}
	}
	, methods: {
		getTransCommis: async function(){
			try{
				const result = await this.$Request({
					method: 'post'
					,url: this.$api_url.api_path.get_trans_commis
					,data: {
						blockchain_div_code: this.$codes.tokens.klay.blockchain_div_code
						, coin_token_code: this.$codes.tokens.klay.coin_token_code
						, member_number: this.user.member_number
						, receive_wallet_address: this.receive_wallet_address
					}
					,type: true
				})

				if(result.success){
					this.tranmsn_commis = result.data.tranmsn_commis
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: e})
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, toPin: function(){
			if(!this.receive_wallet_address){
				this.$bus.$emit('notify', { type: 'error', message: this.$language.nft.address_withdraw_require_02})
			}else{
				this.$emit('click', this.receive_wallet_address)
			}
		}

	}
}
</script>